import {useContext, useEffect} from 'react'
import logo from './logo.svg';
import './App.css';
import {StoreContext} from './contexts/store'
import { Header } from './components/header';
import { Helmet } from 'react-helmet';
import '@rodrigoant/dew-drop'

function App() {
  
  const [count, setCount] = useContext(StoreContext);

  const handleClick = () => {
    setCount(count + 1);
  }

  return (
    <>
      <Helmet>
        <title>{count ? count.toString() : "Ainda não"}</title>
      </Helmet>
      <div className="App">
        <Header data={count} />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <button onClick={handleClick}>{count}</button>
          <dew-button>Teste</dew-button>
        </header>
      </div>
    </>
  );
}

export default App;