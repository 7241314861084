import { createContext, useEffect, useState } from 'react'

// create context named store
export const StoreContext = createContext();

// create a provider component
export const StoreProvider = ({ children }) => {

    let currentCount = parseInt(localStorage.getItem('bgcolor'));
    

    // save count in localstorage
    const [count, setCount] = useState(currentCount ? currentCount : 0);

    useEffect(() => {
        console.log(count);
        localStorage.setItem('bgcolor', count);
    }, [count]);

    return (
        <StoreContext.Provider value={[count, setCount]}>
            {children}
        </StoreContext.Provider>
    )
}

